/* SEARCH */
#search-form {
    margin: auto;
    text-align: center;
    /* width: 50%; */
    padding: 1%;
    border-radius: 5%;
  }
  
  #search-input {
    margin: .5%;
    padding: 1.5em;
    border-radius: 5%;
    width: 20em;
    text-align: center;
    margin-top: 5%;
  }
  
  #jokes-list {
    margin: auto;
    text-align: auto;
    padding: 2%;
    list-style-position: outside;
  }
  
/* FORMS STYLING */
.sign-up-form {
    margin: auto;
    text-align: center;
    width: 50%;
  }
  
  .form-header {
    font-weight: bold;
    font-size: 125%; 
    text-align: auto;
    color: whitesmoke;
  }
  
  .form-fields {
    padding: 2%;
    margin: 2%;
    text-align: auto;
  }
  
  .submit-button {
    padding: 2%;
    margin: 2%;
    text-align: center;
  }
  
  #class-form {
    background: rgb(109, 109, 109);
  }
  
  #hook-form-1 {
    background: rgb(1, 41, 48);
  }
  
  #hook-form-2 {
    background: rgb(4, 63, 43);
  }
  
  #hook-form-3 {
    background: rgb(39, 19, 3);
  }  