.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
  position:absolute;
  top:5px;
  left:20px;
}


.Vizuly-logo {
  height: 60px;
  position:absolute;
  top:15px;
  right:0px;
}

.chart-container {
  position: relative;
  display: flex;
  padding-top: 0 !important; 
  padding-bottom: 0vh;
  justify-content: center;
  align-items: center; 
  margin-top: -125px;
}

.chart-axis {
  position: absolute;
  margin-top: -7px;
  margin-left: 3px;
}

.chart-display-user {
  margin-top: -7em;
  margin-bottom: 2em;
  text-align: center;
}

@media (max-width: 600px) {
  .chart-display-user {
    margin-top: -5em;
  }

  .chart-container {
    margin-top: -70px;
  }

  .chart-axis {
    margin-top: -0.83767%;
    margin-left: 0.408%;
    width: 100% !important;
  }
  .Vizuly-container {
    overflow: hidden;
    width: 100%;
  }
  
}

/* .Vizuly-component {
  border-radius:100px;
  border:1px solid #DDD;
  width:600px;
  height:600px;
  margin:0px auto;
  overflow: hidden;
} */

.Vizuly-title {
  text-align: center;
  width:100%;
  margin-bottom:10px;
}

.App-header {
  background-color: #282c34;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
