@import url('https://fonts.googleapis.com/css?family=Questrial&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

.specialform {
  max-width: 500px;
  margin: 0 auto;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
  padding-bottom: 0em;
  background-color: #f5f5f5;
  font-family: 'Montserrat', sans-serif;
}

.specialform2 {
  margin: 0 auto;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
  padding-bottom: 0em;
  font-family: 'Montserrat';
}

.specialform3 {
  max-width: 800px;
  margin: 0 auto;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
  padding-bottom: 0em;
  font-family: 'Montserrat', sans-serif;
}


.specialinput {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.specialinput2 {
  display: block;
  box-sizing: border-box;
  width: 25%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.speciallabel {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: #4186F0;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.specialinput[type="submit"] {
  background: linear-gradient(180deg, #2AFFE2 0%, #2F82FF 100%);
  color: white;
  /* text-transform: uppercase; */
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  border-radius: 15px;
  font-family: 'Montserrat';
}

.specialinput[type="submit"]:hover {
  background: linear-gradient(180deg, #4191F1 0%, #812CC9 100%);

}

.specialinput[type="button"]:active,
.specialinput[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
  color: blue;
}

.specialinput:disabled {
  opacity: 0.4;
}

.specialinput[type="button"]:hover {
  transition: 0.3s all;
}

.specialinputinput[type="button"],
.specialinputinput[type="submit"] {
  -webkit-appearance: none;
}


