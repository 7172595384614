
.friendship-question {
  margin-top: 3em;
}


.friendship-question .question-header {
  color: #6d7072;
  font-size: 25px;
  max-width: 540px;
  margin: auto;
  font-weight: bold;
  text-align: center;
}

.friendship-question .question-body {
  margin-top: 2em;
  margin-bottom: 2em;
}

.friendship-question .question-title {
  text-align: center;
}

.friendship-question .question-footer .sender-email{
  color: #812CC9;
}

.friendship-question .MuiFormControlLabel-root .MuiFormControlLabel-label {
  /* font-family: 'Helvetica', sans-serif; */
  font-size: 12px;
}

.friendship-question .MuiFormControlLabel-root .MuiRadio-root {
  /* font-family: 'Helvetica', sans-serif; */
  padding: 4px;
}
.friendship-question .MuiFormControlLabel-root .MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
  color: #1976d2;
}

.friendship-question input[type="submit"].next_btn {
  background-image: url(../../illustrations/Next_Button.svg);
  background-repeat: no-repeat;  /* make the background image appear only once */
  background-position: center;  /* equivalent to 'top left' */
  border: none;           /* assuming we don't want any borders */
  padding-left: 25pt;     /* make text start to the right of the image */
  /* margin-top: -2em; */
  vertical-align: middle; /* align the text vertically centered */  
  text-align: left;

  width: 120px;
  height: 36px;
  color: transparent;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
}

.friendship-question input[type="submit"].next_btn:hover {
  background-color: rgb(221, 221, 221);
}

.friendship-question .question-footer .btn-wrapper {
  text-align: center;
  margin-top: 1em;
}

@media (max-width: 600px) {
  .friendship-question {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .friendship-question .question-footer {
    flex-direction: column;
  }
}