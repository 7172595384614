.inline-content {
  display: inline;
}
.inline-content button.MuiButton-root {
  padding: inherit;
  font-size: inherit;
  text-transform: inherit;
  font-family: inherit;
}
.invite-button {
  margin-top: -3px;
  height: 24px;
}
.invite-button button.MuiButton-root{
  padding: 0;
}
.alt-content {
  letter-spacing: 0.2px !important;
  line-height: 130% !important;
  margin-bottom: 24px !important;
}
#form-dialog-content {
  padding-top: 0px;
}

#invite-form {
  padding-top: 16px;
  border-top: 1px solid #4186F0;
  min-height: 300px;
}

#invite-form .friendship-intro {
  margin-bottom: 2em !important;
}

#invite-form .reporter-email {
  text-decoration: none;
  color: #d500f9;
}

#invite-form .friendship-confirm {
  width: 120px;
}

#invite-form .friendship-action {
  display: flex;
  align-items: center;
}

#invite-form .friendship-action .MuiSvgIcon-root {
  margin-left: 2em;
}

#invite-form .form-group,
#invite-form .MuiFormLabel-root {
  font-family: 'Helvetica', sans-serif;
}

#invite-form .form-group {
  margin-bottom: 24px;
}

#invite-form .form-group input[type="email"] {
  font-family: 'Helvetica', sans-serif;
  font-size: 12px;
}

#invite-form .form-group .MuiSelect-select {
  min-width: 220px;
}

#invite-form [role="radiogroup"] span.MuiFormControlLabel-label,
#invite-form .MuiInput-input {
  font-family: 'Helvetica', sans-serif;
  font-size: 12px;
  color: black;
}

#invite-form [role="radiogroup"] span.MuiRadio-root {
  padding: 0 9px;
  align-self: flex-start;
}

#invite-form .form-group label.MuiFormLabel-root {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color:black;
}

#invite-form span.MuiButton-label {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
}

#invite-form .fine-print {
  margin: 16px 0;
  font-size: 12px;
  font-family: 'Helvetica', sans-serif;
}

.friend-option.MuiMenuItem-root {
  font-size: 12px ;
  font-family: 'Helvetica', sans-serif;
}
