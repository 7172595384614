.nav-bar {
    top: 0;
    left: auto;
    right: 0;
    position: sticky;
    z-index: 100;
}

.bar-background{
    background: black;
}

.sign-buttons.MuiButton-root{
    color: white;
}
.sign-buttons.MuiButton-outlined{
    border-width: 2px;
    border-color: white;
}
.sign-buttons .MuiButton-label{
    font-weight: 600;
}

.m-r-2 {
    margin-right: 1.5em !important;
}