.global-banner {
  background-color: #E5138E;
  font-family: Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
  position: relative;
}

.global-banner p {
  margin: 0;
  padding: 2px;
}

.link-launch {
  text-decoration: underline;
  cursor: pointer;
}
.link.link-launch {
  color: rgb(129, 44, 201);
}
