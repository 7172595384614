.feedback-trigger {
  color: #4f4f4f;
  border: 1px solid #4f4f4f90;
  font-size: 14px;
  font-family: Helvetica, sans-serif;
  margin-top: 5em;
  padding: 0 5px;
  width: 200px;
  border-radius: 2px;
}
.feedback-trigger p {
  margin-left: 5px;
  margin-top: 11px;
  margin-bottom: 11px;
  font-size: 14px;
}

.feedback-trigger a {
  text-decoration: underline;
  cursor: pointer;
}

.feedback-modal .MuiFormControl-marginDense	{
  padding: 5px 10px;
}

.feedback-modal textarea	{
  font-size: 15px;
  padding: 5px;
  font-family: Helvetica, sans-serif;
}
.feedback-modal textarea:focus-visible {
  outline: none;
}

.feedback-form {
  min-height: 430px !important;
}

.feedback-form .friendship-confirm {
  width: 200px !important;
}

.feedback-form .MuiAlert-message{
  font-size: 13px;
  font-family: Helvetica, sans-serif;
}

.feedback-form .MuiAlert-root {
  padding: 4px 16px;
}

.feedback-form #contactOptIn {
  margin-right: 5px;
}

.text-muted {
  color: #6c757d!important;
}

.text-danger {
  color: rgb(220,53,69)!important;
}

.text-right {
  text-align: right;
}