.xylaresultstop {
    border: 3px solid #812CC9;
    /* border-left: 4px solid #4186F0;
    border-right: 4px solid #4186F0; */
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 30px;
    background: #F2F2F2;
}

.xylaresultbottom {
    border: 3px solid #812CC9;
    /* border-left: 4px solid #4186F0;
    border-right: 4px solid #4186F0; */
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 30px;
    background: #F2F2F2;
}


.below-direction {
    height: 10px;
    margin-top: 5em;
    text-align: center;
}

.below-direction svg {
    font-size: 2em;
    animation-name: up-and-down;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes up-and-down {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(0px, 5px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}